<template>
  <div class="login">
    <img :src="ActivityInfo.OpenImg" v-if="ActivityInfo" class="OpenImg">
    <div class="next" @click="next" :style="nextStyle" v-if="ActivityInfo">
      进入
    </div>
    <LoginPhone ref="LoginPhone" class="loginmain" :style="LoginStyle"></LoginPhone>
    <!-- <LoginRegister ref="LoginName" v-if="Register"></LoginRegister> -->
  </div>
</template>

<script>
// import { visitAdd} from '../../../api/login'
// import LoginRegister from './LoginRegister.vue'
import LoginPhone from './LoginPhone'
import {init, visitAdd} from "@/api/login";
import {Notify} from "vant";


export default {
  data() {
    return {
      nextStyle: '',
      LoginStyle: '',
      ActivityInfo: {},
      Register: false
    }
  },
  mounted() {
    this.activityinit()
  },
  components: {
    LoginPhone,
    // LoginRegister,
  },
  methods: {
    openRegister() {
      this.Register = true
    },
    closeRegister() {
      this.Register = false
    },
    PvAdd() {
      let CompanyID = localStorage.getItem("CompanyID")
      let ActivityID = this.ActivityInfo.id
      visitAdd({CompanyID, ActivityID})
    },
    next() {
      this.nextStyle = 'opacity:0'
      this.LoginStyle = 'opacity:1;'
    },
    activityinit() {
      let CompanyID = localStorage.getItem("CompanyID")
      init({CompanyID}).then(res => {
        if (res.errorCode == 200) {
          let ActivityInfo = res.payload
          localStorage.setItem("ActivityInfo", JSON.stringify(ActivityInfo));
          this.ActivityInfo = ActivityInfo

          Notify({type: 'success', message: '活动获取成功'})
          this.PvAdd();
          this.userinit()
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      })
    },
    userinit() {
      let userInfo = localStorage.getItem("user")
      if (userInfo) {
        this.$router.push('/exhibit');
        this.$store.state.userInfo = JSON.parse(userInfo)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login {

  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .next {
    position: absolute;
    height: 35px;
    width: 100px;
    left: 50%;
    bottom: 80px;
    border-radius: 7px;
    margin-left: -50px;
    text-align: center;
    line-height: 35px;
    font-size: 13px;
    border: 4px solid #fff;
    color: #fff;
    transition: 0.5s all
  }

  .loginmain {
    opacity: 0;
    transition: 0.5s all
  }

  .OpenImg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .bg {
    width: 100%;
    height: 100%;
    overflow-y: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
    }

  }
}

</style>
