<template>
  <div class="main">
    <div class="main1">
      <span class="txt1">+86</span>
      <input v-model="Phone" placeholder="请输入手机号码"/>
    </div>
    <div class="main2">
      <input v-model="Code" type="number" maxLength="6"/>
    </div>
    <div class="main3" @click="set">{{ StartText }}</div>
    <div class="main5" @click="login">立即登录</div>
    <!-- <div class="register" @click="register">注册</div> -->
    <div class="main6">
      <input name="Fruit" type="checkbox" v-model="lawStart"/>
      <span class="word">我已阅读并同意<a @click="getlaw('protocol')">用户协议</a>及<a
          @click="getlaw('privacy')">隐私政策</a></span>
      <div style="text-align: center;">(未注册将自动创建账号)</div>
    </div>
    <law ref="law"></law>
    <load ref="load"></load>
  </div>
</template>

<script>
import load from '../../../components/load'
import law from "./law";
import {Dialog, Notify} from "vant";
import {verifyCode, setCode} from "../../../api/login";

export default {
  data() {
    return {
      Phone: '',
      Code: '',
      lawStart: false,
      StartText: '发送验证码',
      time: 0
    }
  },
  components: {
    law,
    load,
    [Notify.Component.name]:
    Notify.Component,
    [Dialog.Component.name]:
    Dialog.Component,
  },
  methods: {
    register() {
      this.$parent.openRegister()
    },
    getlaw(str) {
      this.$refs.law.init(str)
    },
    login() {
      if (!this.lawStart) {
        Dialog.alert({
          message: '请勾选协议政策',
        });
        return;
      }

      let Phone = this.Phone
      let Code = this.Code

      if (!Phone) {
        Dialog.alert({
          message: '请填写手机号码',
        });
        return;
      }

      if (!Code) {
        Dialog.alert({
          message: '请填写验证码',
        });
        return;
      }

      this.$refs.load.open()
      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))


      verifyCode({Phone, Code, CompanyID}).then(res => {
        this.$refs.load.close()
        if (res.errorCode == 200) {
          Notify({type: 'success', message: '登录成功'})
          let userInfo = res.payload
          localStorage.setItem("user", JSON.stringify(userInfo));
          this.$store.state.userInfo = userInfo
          this.$router.push('/exhibit')
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      }).catch(() => {
        this.$refs.load.close()
        Dialog.alert({
          message: '服务端故障，请与技术人员联系',
        });
      })
    },
    //发送验证码
    set() {
      let time = this.time
      if (!this.Phone) {
        Dialog.alert({
          message: '未输入手机号码',
        });
        return;
      }
      if (time == 0) {
        if (/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(this.Phone)) {
          let Phone = this.Phone
          this.$refs.load.open()
          setCode({Phone}).then(res => {
            this.$refs.load.close()
            if (res.errorCode == 200) {
              Dialog.alert({
                message: res.payload,
              });

              this.time = 60
              this.Start = true
              this.CodeTime = setInterval(() => {
                let times = this.time
                times = times - 1
                if (times == 0) {
                  clearInterval(this.CodeTime)
                  this.StartText = '请发送验证码'
                  this.time = 0
                  return
                }
                this.time = times
                this.StartText = times + '秒再次发送'
              }, 1000)
            } else {
              Dialog.alert({
                message: res.errorMsg,
              });
            }
          }).catch(() => {
            this.$refs.load.close()
            Dialog.alert({
              message: '服务端故障，请与技术人员联系',
            });
          })
        } else {
          Dialog.alert({
            message: '手机号码不符合规则',
          });
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 270px;
  width: 310px;
  background-color: rgba(249, 249, 249, 0.97);
  box-shadow: 0 1px 8px 0 rgba(143, 143, 143, 0.5);
  align-self: center;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -155px;
  margin-top: -150px;

  .main1 {
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    width: 267px;
    position: absolute;
    left: 20px;
    top: 30px;

    .txt1 {
      width: 40px;
      height: 40px;
      display: block;
      color: rgba(210, 210, 210, 1);
      font-size: 14px;
      line-height: 40px;
      position: absolute;
      left: 0;
      text-align: center;
    }

    input {
      top: 0;
      position: absolute;
      border: none;
      right: 5px;
      width: 210px;
      height: 40px;
      outline: none;
      font-size: 14px;
      padding: 0;
    }
  }

  .main2 {
    width: 139px;
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    position: absolute;
    left: 20px;
    top: 90px;

    input {
      top: 0;
      position: absolute;
      border: none;
      left: 0;
      width: 100%;
      height: 100%;
      outline: none;
      text-indent: 1rem;
      font-size: 13px;
      padding: 0;
    }
  }

  .main3 {
    width: 107px;
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    position: absolute;
    left: 180px;
    top: 90px;
    color: #1989fa;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
  }

  .main5 {
    height: 44px;
    background-color: #1989fa;
    color: #FFFFFF;
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    width: 267px;
    position: absolute;
    text-align: center;
    left: 20px;
    top: 150px;
    line-height: 44px;
    font-size: 14px;
  }

  .register {
    height: 44px;
    color: #000;
    width: 267px;
    position: absolute;
    text-align: center;
    left: 20px;
    top: 200px;
    line-height: 44px;
    font-size: 14px;
  }

  .main6 {
    width: 257px;
    height: 24px;
    position: absolute;
    font-size: 14px;
    top: 215px;
    left: 20px;

    input {
      width: 20px;
      display: inline-block;
      margin-right: 5px;
    }

    a {
      color: rgba(29, 170, 196, 0.81);
      text-decoration: 1px underline;
    }
  }
}
</style>
